import React from 'react';
import BannerImage from '../BannerImage/BannerImage';
import Service from '../Service/Service';
import Served from '../Served/Served';
import ServiceOffered from '../ServiceOffered/ServiceOffered';
import OurTeam from '../OurTeam/OurTeam';

const Home = () => {
    return (
        <React.Fragment>
            <BannerImage />
            <Service />
            <Served />
            <ServiceOffered />
            <OurTeam />
        </React.Fragment>
    );
}

export default Home;