import React from 'react';
import icon from "../../assets/images/title-icon.png";
import { OurteamData } from '../Data/OurteamData';
import "./our-team.scss";
const OurTeam = () => {
  return (
    <div className="our-team-sec mb-40">
      <div className="container">
        <div className="title-sec btn-shadow">
          <h3>MEN AT HELM</h3><img src={icon} alt="title-icon" className="img-fluid" />
        </div>
        <div className="team-list">
          {OurteamData.map((member, index) => (
            <div key={index} className="team-member">
              <h4>{member.name}</h4>
              <span>{member.role}</span>
              <p>{member.description}</p>
              <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                <span><svg title="Linkedin" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M8 19H5V9h3zm11 0h-3v-5.342c0-1.392-.496-2.085-1.479-2.085c-.779 0-1.273.388-1.521 1.165V19h-3s.04-9 0-10h2.368l.183 2h.062c.615-1 1.598-1.678 2.946-1.678c1.025 0 1.854.285 2.487 1.001c.637.717.954 1.679.954 3.03z"/><ellipse cx="6.5" cy="6.5" fill="currentColor" rx="1.55" ry="1.5"/></svg></span> 
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default OurTeam;
