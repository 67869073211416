import React from 'react';
import { ServedData } from '../Data/ServedData';
import BgImage from "../../assets/images/bg-right.png";
import icon from "../../assets/images/title-icon.png";
import "./served.scss";
const Served = () => {
  return (
    <div className="served-sec mb-40" >
      <div className="container">
        <div className="served-sec-wrapper">
          <img src={BgImage} alt="right-img" className='right-img img-fluid' />
          <div className="title-sec btn-shadow">
            <h3>VERTICALS SERVED</h3><img src={icon} alt="title-icon" className="img-fluid" />
          </div>
          <ul>
            {ServedData.map((item, index) => (
              <li key={index}>
                <span>{item.title}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Served;
