import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Footer/Footer";
import BottomToTop from "./BottomToTop/BottomToTop";
import Home from "./Home/Home";
import OurTeam from "./OurTeam/OurTeam";
import ContactUs from "./ContactUs/ContactUs";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

function Layout() {
  // const path = window.location.pathname;
  // const locations = [
  //   "/"
  // ];
  
  return (
    <React.Fragment>
      <ScrollToTop />
        <Routes>
        <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<ContactUs />} >
          </Route>
        </Routes>
      <Footer />
      <BottomToTop />
    </React.Fragment>
  );
}

export default Layout;
