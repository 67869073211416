import React from 'react';
import LeafIcon from "../../assets/images/leaf.png";
import ServiceData from '../Data/ServiceData';
import './service.scss';
const ServiceSection = () => {
    return (
        <div className="service-content mb-40">
            <div className="container">
                <div className="service-wrapper">
                    <p>
                        Newly established in Abu Dhabi, <span>Veritech Engineering Services LLC</span> offers a wide range of
                        engineering services across various Industry verticals. VES brings to together the three
                        important aspects of this industry namely
                    </p>
                    <ul>
                        {ServiceData.map((service) => (
                            <li key={service.id}>
                                <img src={LeafIcon} alt="icon" className="iocn img-fluid" />
                                <span>{service.title}</span>
                            </li>
                        ))}
                    </ul>
                    <p>
                        The Middle East operations are headquartered in Abu Dhabi, and this is supported by our
                        Centre of Excellence being established in Pune, India. <strong>Veritech Engineering Services LLC</strong> provides a wide range of engineering services across industry verticals.
                    </p>
                </div>
            </div>
        </div>
    );
};
export default ServiceSection;