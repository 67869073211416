export const ServedData = [
  {
    title: "Oil and Gas Petrochemical Industries",
  },
  {
    title: "Onshore and Offshore Piping, Gas Networks, and Storage",
  },
  {
    title: "Marine Engineering, Energy Sector",
  },
  {
    title: "Carbon Capture and Green Hydrogen, Roads, Bridges, and Infrastructure",
  },
  {
    title: "Metallurgy and Mining, Cement and Gypsum",
  },
  {
    title: "Structural Steel Fabrication, ETO/BTO Engineering Equipment",
  },
  {
    title: "Bespoke Engineered Products, Engineering Training",
  },
];
