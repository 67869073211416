import React, { useEffect } from 'react';
import $ from 'jquery';
import "./bottomtotop.scss";
function BottomToTop() {
  useEffect(() => {
    const progressPath = document.querySelector('.progress-bottom path');
    const pathLength = progressPath.getTotalLength();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
    progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'strokeDashoffset 10ms linear';
    const updateProgress = () => {
      const scroll = $(window).scrollTop();
      const height = $(document).height() - $(window).height();
      const progress = pathLength - (scroll * pathLength / height);
      progressPath.style.strokeDashoffset = progress;
    };
    updateProgress();
    $(window).scroll(updateProgress);
    const offset = 150;
    const duration = 550;
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > offset) {
        $('.progress-bottom').addClass('active-progress');
      } else {
        $('.progress-bottom').removeClass('active-progress');
      }
    });
    $('.progress-bottom').on('click', function (event) {
      event.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, duration);
      return false;
    });
  }, []);
  return (
    <div className="cursor-pointer progress-bottom">
      <svg className="progress-bar-icon svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{ transition: 'strokeDashoffset 10ms linear 0s', strokeDasharray: '307.919, 307.919', strokeDashoffset: '0.283792' }}></path>
      </svg>
    </div>
  );
}
export default BottomToTop;