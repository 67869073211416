
import React from 'react';
import BannerImageData from '../Data/BannerImageData';
import BannerLogo from "../../assets/images/banner-logo.png";
import "./banner-image.scss";

const BannerImage = () => {
    return (
        <>
            <div className="banner-wrapper mb-50">
                <div className="container">
                    <div className="banner-section-wrapper">
                        {BannerImageData.map((data, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-12">
                                    <div className="banner-image">
                                        <img src={data.image} alt="banner-img" className="img-fluid banner-main-image" />
                                        <div className="banner-logo btn-shadow">
                                            <a className="logo" href="/"><img src={BannerLogo} alt="Banner-logo" className="img-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BannerImage;
