import React from 'react';
import { ServiceOfferedData } from '../Data/ServiceOfferedData';
import { AnotherServiceData } from '../Data/ServiceOfferedData';
import BgImage from "../../assets/images/bg-left.png";
import icon from "../../assets/images/title-icon.png";
import "./service-offered.scss";

const ServiceOffered = () => {
  return (
    <div className="service-offerd-sec mb-40">
      <div className="container">
        <div className="service-offerd-wrap">
          <img src={BgImage} alt="right-img" className="left-img img-fluid" />
          <div className="title">
            <div className="title-sec btn-shadow">
              <h3>SERVICES OFFERED</h3>
              <img src={icon} alt="title-icon" />
            </div>
          </div>
          <div className="service-sec">
            <ul>
              {ServiceOfferedData.map((item, index) => (
                <li key={index}>
                  <span>{item.title}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="title">
            <div className="title-sec btn-shadow">
              <h3>FIELD OF EXPERTISE </h3>
              <img src={icon} alt="title-icon" className="img-fluid" />
            </div>
          </div>
          <ul>
            {AnotherServiceData.map((item, index) => (
              <li key={index}>
                <span>{item.title}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceOffered;
