export const ServiceOfferedData = [
  {
    title: "Basic Engineering Design",
  },
  {
    title: "Detailed Engineering Design and Draughting Services",
  },
  {
    title: "Engineering Calculations and Documentation",
  },
  {
    title: "As built Engineering Services",
  },
];

export const AnotherServiceData = [
  {
    title: "Mechanical",
  },
  {
    title: "Electrical and Wiring",
  },
  {
    title: "Process and Instrumentation",
  },
  {
    title: "Automation",
  },
  {
    title: "Structural Engineering Civil Engineering incl. foundations",
  },
  {
    title: "Project Engineering and Layouts",
  }
];