const ServiceData = [
  {
    id: 1,
    title: "Trained Resources",
  },
  {
    id: 2,
    title: "Software Support",
  },
  {
    id: 3,
    title: "Project Management and Execution",
  }
];
export default ServiceData;
