import React from 'react';
import "./contact-us.scss";
import BannerImage from '../BannerImage/BannerImage';
const ContactForm = () => {
  return (
    <>
      <BannerImage />
      <div className="contact-section-wrapper">
        <div className="container">
          <div className="sec-title text-center">
            <h2>Get in Touch with <br /> Veritech Engineering Services</h2>
          </div>
          <div className="contact-form">
            <div className="col-lg-8 m-auto">
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input className="form-control" type="text" placeholder="Enter Name" required/>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input className="form-control email" type="email" placeholder="Enter Email" required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input className="form-control" type="text" placeholder="Enter Subject" required/>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input className="form-control" type="text" placeholder="Enter Phone" required/>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <textarea className="form-control" rows="5" placeholder="Enter Message"></textarea>
                </div>
                <div className="text-center">
                  <button type="submit" className="submit-btn btn-shadow">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactForm;