export const OurteamData = [
  {
    name: "ravi achanta",
    role: "Chairman",
    description: "Ravi is US based Entrepreneur, Technology & Digital Enthusiast, Investor, Consultant and Business Developer.",
    linkedin: "https://www.linkedin.com/in/ravi-achanta/"
  },
  {
    name: "prasad natu",
    role: "Chief Executive Officer",
    description: "Prasad is US based Entrepreneur, Investor, Consultant and Business Advisor with over 35 years of global experience.",
    linkedin: "https://www.linkedin.com/in/prasadnatu/"
  },
  {
    name: "chanchal roy",
    role: "General Manager",
    description: "Chanchal is an engineering professional with over 34 years of experience in managing and operating engineering organizations.",
    linkedin: "https://www.linkedin.com/in/chanchalroy/"
  }
];
