import React from "react";
import "./footer.scss";
import FooterLogo from "../../assets/images/logo.jpg";
function Footer() {
    return (
        <>
            <footer className="footer-main">
                {/* <div className="container"> */}
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="company-info">
                            <div className="footer-image">
                                <a className="logo" href="/"><img src={FooterLogo} alt="footer-logo" className="img-fluid" />
                                </a>
                            </div>
                            <div className="footer-btn btn-shadow">
                                <a href="/contact" className="cta-button">Tap To Get in Touch</a>
                            </div>
                        </div>
                        <div className="company-details">
                            <p>1805 Al Khazna Tower, 3 Al Majarat St, Al Danah, Abu Dhabi 22220, United Arab Emirates</p>
                            <a href="https://www.veritechengineeringservices.com/" className="company-website">
                            www.veritechengineeringservices.com
                            </a>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </footer>
        </>
    );
}
export default Footer;
